import Link from "next/link";
import React from "react";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  classes?: string;
  href?: string;
  theRef?: React.MutableRefObject<any>;
}

const BaseBtn: React.FC<Props> = ({ classes, href, children, theRef, ...props }) => {
  const btnClasses = `${classes} clg-app-btn font-action !outline-none transition-all cursor-pointer box-border group flex-shrink-0 whitespace-nowrap`;

  return (
    <>
      {href ? (
        href.includes("http") ? (
          <a
            className={btnClasses}
            href={href}
            target="_blank"
            rel="noreferrer"
            ref={theRef}
            aria-label={props["aria-label"]}
          >
            {children}
          </a>
        ) : (
          <Link href={href}>
            <a className={btnClasses} ref={theRef} aria-label={props["aria-label"]}>
              {children}
            </a>
          </Link>
        )
      ) : (
        <button className={btnClasses} {...props} ref={theRef}>
          {children}
        </button>
      )}
    </>
  );
};

BaseBtn.defaultProps = {
  type: "button",
  classes: "",
};

export default BaseBtn;
