import { utimes } from "fs";
import { BreakPoint } from "../../../components/hooks/useScreenSize";
import {
  CartItem,
  CountryInterface,
  CouponItemInterface,
  ProductItemInterface,
  StoreInterface,
  VariantForm,
  VariantItem,
} from "../../interfaces";
import { capitalizeFirstLetter, genarateStringFromVariantValues, removeCountryCode } from "./functions";

const checkStoreIsOpen = (store?: StoreInterface) => {
  if (store) {
    const today = new Date();
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const dayOfTheWeek = days[today.getDay()];
    const tomorrow = dayOfTheWeek === "saturday" ? days[0] : days[today.getDay() + 1];
    const tomorrowsStoreHoursExist = Object.keys(store?.configuration?.hours || {}).some((key) => key === tomorrow);
    let storeTime: string[];

    try {
      storeTime = store.configuration.hours[dayOfTheWeek].split("-");
      const dayHour = today.getUTCHours() + 1; /**/
      const dayMinutes = today.getUTCMinutes();
      const currentDayMinutes = (dayHour === 24 ? 0 : dayHour) * 60 + dayMinutes; /**/

      //calculate minutes of for the respective selected times
      let [opening, closing] = storeTime.map((h, i) => {
        const isAM = h.includes("AM");
        const hWithoutAMPM = h.slice(0, 5);

        const [hours, minutes] = hWithoutAMPM.split(":").map((t, index) => {
          const isHours = index === 0;
          const time = Number(t);

          if (!isAM && isHours && time < 12) {
            return time + 12;
          }

          if (isAM && time === 12 && isHours) {
            /**/
            return time - 12;
          }

          return time;
        });

        return hours * 60 + minutes;
      });

      let isOpen: boolean;

      // Making sure closing is always greater than opening e.g. for 9am - 4am
      if (opening <= closing) {
        isOpen = currentDayMinutes >= opening && currentDayMinutes <= closing;
      } else {
        isOpen = currentDayMinutes >= opening || currentDayMinutes <= closing;
      }

      return {
        open: isOpen,
        visitedBeforeOpening: currentDayMinutes < opening,
        visitedAfterClosing: currentDayMinutes > closing,
        openTime: storeTime[0],
        openTomorrowTime: tomorrowsStoreHoursExist ? store.configuration.hours[tomorrow].split("-")[0] : "",
      };
    } catch (err) {
      if (storeTime === undefined) return { open: true };
    }

    if (storeTime === undefined) return { open: true };
  }

  return { open: true };
  // Detect whether store is visited before/during opening hours, or after closing hours
  // Error occurs when the day user visits does not exist in hours object, Store is set to open (true) at this point too
  // Info useful for getStoreIsClosedMsgToDisplay below
  // Lines labelled /**/ are places to 1st check if anything doesn't work well (probably after any new change is made/added)
};

const getDropdownOptionsFromCountries = (countries: CountryInterface[]) => {
  if (!countries) return [];
  return countries.map((c) => ({ text: `${c.name} ${c.emoji}`, value: c.code }));
};

const formatDateString = (date: Date, format: "default" | "ddmm" = "default") => {
  const split: string[] = date.toDateString().split(" ");
  switch (format) {
    case "default":
      split[0] += ",";
      return split.join(" ");
    case "ddmm":
      return `${split[2]} ${split[1]} `;
  }
};

function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

const getDiscountPercentage = (item: ProductItemInterface) => {
  const { price, discount_price } = item;
  const discount = discount_price ? discount_price : price;

  return Math.ceil(((price - discount) / price) * 100);
};

const getActualPrice = (item: ProductItemInterface | VariantItem) => {
  return item?.discount_price ? item?.discount_price : item.price;
};

const getMainPrice = (item: ProductItemInterface | VariantItem) => {
  return item.price;
};

const getEnumValues = (enumObj: any) => {
  return Object.keys(enumObj).filter((item) => {
    return isNaN(Number(item));
  });
};

const getProductOptionsLength = (variants: VariantForm) => {
  if (variants.type === "custom") {
    return variants?.options?.length;
  }

  const variantHasExtraOption = variants.type === "images" && variants?.options[0]?.values !== undefined;

  if (variantHasExtraOption) {
    return Array.from(new Set(variants.options.map((o) => o.image))).length;
  }

  return variants?.options?.length;
};

const getStoreFrontSEOTags = (slug: string, store: StoreInterface) => {
  if (!store) {
    return {
      title: "Not found - Catlog",
      description: "Store was not found.",
      pageUrl: `/${slug}`,
      image: "https://res.cloudinary.com/catlog/image/upload/v1671183044/seo-banners/Not_Found_Banner.png",
    };
  } else {
    return {
      title: `${store.name}`,
      description: store.description,
      pageUrl: `/${slug}`,
      image:
        store.logo || "https://res.cloudinary.com/catlog/image/upload/v1674315802/seo-banners/Store_Link_Banner.png",
    };
  }
};

const resolvePhone = (phone: string) => {
  if (phone?.startsWith("0")) {
    return phone?.replace("0", "234");
  }

  return phone?.replace("-", "").replace("+", "");
};

const getFullProductName = (c: CartItem) => {
  let name = "";

  if (c?.variant) {
    if (c?.item?.variants.type === "custom") {
      name = `${capitalizeFirstLetter(c.item.name)} (${genarateStringFromVariantValues(c?.variant?.values)})`;
    } else {
      const hasExtraOption = c.item?.variants?.options[0]?.values !== undefined;
      name = `${capitalizeFirstLetter(c.item.name)} (Option ${
        c.item.variants.options.findIndex((o) => o.id === c.variant.id) + 1
      }${hasExtraOption ? ` - ${Object.keys(c?.variant?.values)[0]} ${Object.values(c?.variant?.values)[0]}` : ""})`;
    }
  } else {
    name = capitalizeFirstLetter(c.item.name);
  }
  return name.replace(/&/g, "and");
};

const textObserver = (classToRemove?: string, classToAdd?: string) => {
  const IObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove(classToRemove || "text-pre-show");
          entry.target.classList.add(classToAdd || "slide-text-in");

          observer.unobserve(entry.target);
        }
      });
    },
    { threshold: 0.5 }
  );

  const textsToAnimate = document.querySelectorAll(".text-pre-show");
  textsToAnimate.forEach((t) => IObserver.observe(t));

  return IObserver;
};

const checkItemAvailability = (
  item: ProductItemInterface,
  variant?: VariantItem,
  variantOnly: boolean = false
): boolean => {
  let quantityAvailable;

  if (!variant) {
    quantityAvailable =
      item?.is_always_available !== undefined || item?.quantity !== undefined
        ? item?.is_always_available || item.quantity > 0
        : true;
  } else {
    quantityAvailable =
      variant?.is_available &&
      (item?.is_always_available || (variant?.quantity !== undefined ? variant?.quantity > 0 : true));

    if (variantOnly) return quantityAvailable;
  }

  // console.log({
  //   item,
  //   quantityAvailable,
  // });

  return quantityAvailable && item?.available;
};

const getSizedImage = (image: string, sizes: { [key: string]: string }, screen: BreakPoint) => {
  const size = sizes[screen] ?? sizes?.default;
  const sizedUrl = image.replace("/upload/", `/upload/c_scale,${size}/`);

  return sizedUrl;
};

const checkIfExpired = (date?: string) => {
  if (!date) return false;

  const today = new Date().getTime();

  const isExpired = today - new Date(date).getTime() > 24 * 60 * 60 * 1000;

  return isExpired;
};

const getWhatsappLink = (phone?: string, message: string = "") =>
  `https://api.whatsapp.com/send/?phone=${resolvePhone(phone)}&text=${message ? message : "Hi"}`;

const stripAccount = (account: string) => account.replace("- Catlog", "");

const computeItemAvailability = (i: ProductItemInterface) => ({
  ...i,
  available_to_purchase: checkItemAvailability(i),
  variants:
    i?.variants?.options?.length > 0
      ? {
          ...i?.variants,
          options: i?.variants?.options.map((o) => {
            return {
              ...o,
              available_to_purchase: checkItemAvailability(i, o),
            };
          }),
        }
      : undefined,
});

function computePaymentCouponDiscount(totalAmount: number, coupon: CouponItemInterface) {
  let discountAmount: number;
  if (coupon.type == "percentage") {
    discountAmount = (totalAmount * coupon.percentage) / 100;
    const discountExceeded = coupon.discount_cap ? discountAmount > coupon.discount_cap : false;
    discountAmount = discountExceeded ? coupon.discount_cap : discountAmount;
  } else if (coupon.type == "fixed") {
    discountAmount = coupon.discount_amount;
  }

  return discountAmount > totalAmount ? -totalAmount : -discountAmount;
}

const sendFacebookEvent = (eventName, params = {}) => {
  if (window.fbq) {
    window.fbq("track", eventName, params);
  } else {
    //Facebook Pixel is not initialized
  }
};

export {
  checkStoreIsOpen,
  getDropdownOptionsFromCountries,
  formatDateString,
  getDiscountPercentage,
  getActualPrice,
  getMainPrice,
  getEnumValues,
  getProductOptionsLength,
  getStoreFrontSEOTags,
  resolvePhone,
  getFullProductName,
  textObserver,
  checkItemAvailability,
  getSizedImage,
  getWhatsappLink,
  checkIfExpired,
  stripAccount,
  getFormattedDate,
  computeItemAvailability,
  computePaymentCouponDiscount,
  sendFacebookEvent,
};
