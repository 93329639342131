import classNames from "classnames";
import BaseBtn from "./base-btn";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  color?: "primary" | "accent" | "primary-outline" | "neutral" | "danger" | "white" | "success";
  size?: "sm" | "md" | "lg";
  href?: string;
  isBlock?: boolean;
  theRef?: React.MutableRefObject<any>;
}

const AppBtn: React.FC<Props> = (theProps) => {
  const { color = "primary", size = "md", className, children, disabled, isBlock, ref, theRef, ...props } = theProps;

  const colorClasses = {
    accent: "bg-accent-yellow-500 hover:bg-accent-yellow-600 disabled:!bg-accent-yellow-500 text-white",
    neutral:
      "bg-grey-fields-200 hover:bg-opacity-50 text-primary-500 disabled:!text-primary-300 border border-grey-fields-100 hover:border-opacity-80",
    danger: "bg-accent-red-500 hover:bg-accent-red-700 text-white",
    white: "bg-white hover:text-primary-700 text-primary-700",
    success: "bg-success text-white",
    "primary-outline": "bg-white hover:text-primary-700 text-primary-700 border border-primary-700  text-primary-700",
    primary: "bg-primary-500 text-white",
    // black: "bg-primary-500 hover:bg-primary-700 text-white",
  };

  const sizeClasses = {
    sm: "h-9 sm:h-10 px-3.5 sm:px-4 text-1xs sm:text-sm rounded-[8px]",
    md: "h-11 sm:h-12 px-3.75 sm:px-5 text-1xs sm:text-sm rounded-10",
    lg: "h-12.5 sm:h-[55px] px-3.75 sm:px-5 text-sm sm:text-1sm rounded-[13px]",
  };

  const classes = classNames(
    "flex items-center justify-center font-medium",
    {
      "w-full": isBlock,
      "is-disabled": disabled,
    },
    colorClasses[color],
    sizeClasses[size]
  );

  return (
    <BaseBtn classes={`${classes} ${className}`} {...props} disabled={disabled} theRef={theRef}>
      {children}
    </BaseBtn>
  );
};

export default AppBtn;

// import classNames from "classnames";
// import BaseBtn from "./base-btn";

// interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
//   color?: "primary" | "accent" | "primary-outline" | "neutral" | "danger" | "white" | "success";
//   size?: "sm" | "md" | "lg";
//   href?: string;
//   isBlock?: boolean;
//   theRef?: React.MutableRefObject<any>;
// }

// const AppBtn: React.FC<Props> = (theProps) => {
//   const { color = "primary", size = "md", className, children, disabled, isBlock, ref, theRef, ...props } = theProps;

//   const colorClasses = {
//     accent: "bg-accent-yellow-500 hover:bg-accent-yellow-600 disabled:!bg-accent-yellow-500 text-white",
//     neutral:
//       "bg-grey-fields-200 hover:bg-opacity-50 text-primary-500 disabled:!text-primary-300 border border-grey-fields-100 hover:border-opacity-80",
//     danger: "bg-accent-red-500 hover:bg-accent-red-700 text-white",
//     white: "bg-white hover:text-primary-700 text-primary-700",
//     success: "bg-accent-green-500 text-white",
//     "primary-outline": "bg-white hover:text-primary-700 text-primary-700 border border-primary-700  text-primary-700",
//     primary: "bg-primary-500 hover:bg-primary-700 text-white",
//   };

//   const sizeClasses = {
//     sm: "h-9 px-3.5 sm:px-4 text-1xs sm:text-sm rounded-[8px]",
//     md: "h-11 sm:h-12 px-3.75 sm:px-5 text-1xs sm:text-sm rounded-10",
//     lg: "h-12.5 sm:h-[55px] px-3.75 sm:px-5 text-sm sm:text-1sm rounded-[13px]",
//   };

//   const classes = classNames(
//     "flex items-center justify-center font-medium whitespace-nowrap",
//     {
//       "w-full": isBlock,
//       "is-disabled": disabled,
//     },
//     colorClasses[color],
//     sizeClasses[size]
//   );

//   return (
//     <BaseBtn classes={`${classes} ${className}`} {...props} disabled={disabled} theRef={theRef}>
//       {children}
//     </BaseBtn>
//   );
// };

// export default AppBtn;
